import React from 'react';

import MiniHero from '../MiniHero';
import GallerySwiperSlide from './GallerySwiperSlide';
import DesignInspiration from './DesignInspiration';

function Gallery () {
    
    return (
        <div>
            <MiniHero pageName="Gallery" />
            <DesignInspiration />
        </div>
    );
}


export default Gallery;