import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "../../styles/Home/ProductsSection.css";

import { BACKEND_URL } from "../../constants";
import CabinetProductCard from "../ShopPage/CabinetProductCard";


function ProductsSection() {
  const [cabinetsData, setCabinetsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  
  useEffect(() => {
    // Define an async function inside the useEffect to fetch data
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${BACKEND_URL}/api/cabinet/Capital/Shaker/White?$p=5&sort=price`);

        if (!response.ok) {
          setHasMore(false);
          throw new Error(`HTTP error! Status: ${response.status}`);  
        }
        const data = await response.json();
        setCabinetsData(prevData => [...prevData, ...data]);  // Append new data
        setHasMore(data.length > 0);  // Check if there are more results
      } catch (error) {
          console.error('Error fetching data');
      } finally {
          setLoading(false);
      }
    };

    fetchData();
  }, []); 


  return (
    <section className="products-section">
      <div className="products-title">
        <h1>Our Products</h1>
      </div>
      <div className="cabinet-products">
        {cabinetsData.slice(0, 8).map((cabinet) => (
          <CabinetProductCard key={cabinet.id} cabinet={cabinet} />
        ))}
      </div>
      <div className="show-more-link-container">
        <Link to="/doorstyle" className="show-more-link">
          <button className="show-more-btn">Show More</button>
        </Link>
      </div>
    </section>
  );
}

export default ProductsSection;
