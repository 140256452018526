import React from 'react';

const FormField = ({ field_name, field_label, field_type, field_id, field_placeholder, field_value, setValue}) => {

    return (
        <label htmlFor={field_name}>{field_label}
            <input
                style={{ display: 'block' }}
                type={field_type}
                value={field_value}
                id={field_id}
                onChange={(e) => setValue(e.target.value)}
                placeholder={field_placeholder}
                required
            />
        </label>
    );
}

export default FormField;