import React from "react";


function DropdownItem({ containerType, filterType, handler}) {
    
    return (
        <label className="dropdown-container">
            {containerType.charAt(0).toUpperCase() + containerType.slice(1)}
            <input
                type="radio"
                name="type"
                value={containerType}
                onChange={handler}
                checked={filterType === containerType}
            />
            <span className="checkmark"></span>
        </label>
    );
}

export default DropdownItem;