import React from 'react';
import FormField from "./FormField";
import axios from "../../../utils/axios";
import styles from '../../../styles/Home/ContactForm.module.css';
import { useState } from "react";

const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Here you can handle the form submission, e.g., sending the data to an API
        try {
            await axios.post("/api/contact", {
                name,
                email, 
                message, 
                "category": "BUY"
            });
            alert('We have received your message! A representative will contact you soon. Thank you');
        } catch (error) {
            console.error(error);
        }
    };
  
    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <FormField 
                field_name="customer_name"
                field_label="Your Name"
                field_type="text"
                field_id="customer_name"
                field_placeholder="John Smith"
                field_value={name}
                setValue={setName}
            />
            <FormField
                field_name="customer_email"
                field_label="Email address"
                field_type="email"
                field_id="customer_email"
                field_placeholder="example@email.com"
                field_value={email}
                setValue={setEmail}
            />
            <FormField
                field_name="subject"
                field_label="Subject"
                field_type="text"
                field_id="subject"
                field_placeholder="Subject"
                field_value={subject}   
                setValue={setSubject}
            />
            <FormField 
                field_name="customer_message"
                field_label="Message"
                field_type="text"
                field_id="customer_message"
                field_placeholder="Hi! I'd like to ask about"
                field_value={message}
                setValue={setMessage}
            />
            <button type="submit">Submit</button>
        </form>
    )
    

}

export default ContactForm;