
import styles from '../../styles/Home/ContactForm.module.css';
import ContactForm from "./ContactSection/ContactForm"
import ContactInfo from "./ContactSection/ContactInfo"

const ContactSection = () => {


    return (
        <div className="contactSection w-full p-10 mb-12 box-border">
            <h2 className={styles.header}>Get A Free Design Today</h2>
            <p className={styles.paragraph}>For more information about our product & services, please feel free to drop us an email. Our staff always be there to help you out. Do not hesitate!</p>
            
            {//<img className="flex p-0 sm:w-full" src="/assets/call-us.webp"/>
            }

            {/* responsive design */}
            <div className="flex  justify-center md:hidden">
                <div className="contactContainer flex flex-col-reverse items-center h-full">
                    <ContactInfo />
                    <ContactForm />
                </div>
            </div>
        
            <div className="hidden md:flex">
                <div className="contactContainer flex justify-between gap-10 max-w-[70vw] mx-auto mt-14">
                    <ContactInfo />
                    <ContactForm />
                </div>
            </div>
            
            
        </div>
    );
};

export default ContactSection;