import React from 'react';
import { Link } from 'react-router-dom';
import "../../styles/Home/BrowseRange.css";

const BrowseRange = () => {
  return (
    <section className="browse-range">
      <div className="browse-title">
        <h1>Browse The Range</h1> 
      </div>
      <div className="image-container">
        <div className="image-wrapper">
          <div class="browseImgContainer">
            <Link to="../doorstyle#Framed" className="link-bar">
            <div class="browseImgOverlay"></div>
            <img className="browseImg" src="/collections-image/Framed.png" alt="" />
            <button className="browseImgText">Click to view</button>
            <div className="title-bar">Framed</div>
            </Link>
          </div>
          <div class="browseImgContainer">
            <Link to="../doorstyle#Frameless" className="link-bar">
              <div class="browseImgOverlay"></div>
              <img className="browseImg" src="/collections-image/Frameless.png" alt="" />
              <button className="browseImgText">Click to view</button>
              <div className="title-bar">Frameless</div>
            </Link>
          </div>
          <div class="browseImgContainer"> 
            <Link to="doorstyle#ADA" className="link-bar">
              <div class="browseImgOverlay"></div>
              <img className="browseImg" src="/collections-image/ADA.png" alt="" />
              <button className="browseImgText">Click to view</button>
              <div className="title-bar">Universal Design</div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BrowseRange;

