
export function openCollection(tabName) {
  var i, tabContent, tablinks;
  
  // Get all elements with class="tabContent" and hide them
  tabContent = document.getElementsByClassName("tabContent");
  for (i = 0; i < tabContent.length; i++) {
      tabContent[i].style.display = "none";
  }

  // Get all elements with class="tablinks" and remove the class "active"
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
  }

  // Show the current tab, and add an "active" class to the button that opened the tab
  const tab = document.getElementById(tabName);
  if (tab) {
      tab.style.display = "block";
      const activeButton = document.querySelector(`.tablinks[data-tab="${tabName}"]`);
      if (activeButton) {
          activeButton.classList.add("active");
      }
  } else {
      console.error(`Element with id "${tabName}" not found`);
  }
  window.scrollTo(0,0);
}

export function handleHashChange(openCollection) {
    const hash = window.location.hash.substring(1); // Get the hash without the '#' character
    if (hash) {
        openCollection(hash);
    } else {
        const defaultOpenButton = document.getElementById("defaultOpen");
        if (defaultOpenButton) {
            defaultOpenButton.click();
        } else {
            console.error('Element with id "defaultOpen" not found');
        }
    }
}