import React, { useEffect } from 'react';

// components
import MiniHero from "../MiniHero";
import CollectionDescription from "./CollectionDescription";
import DoorImagesDisplay from "./doorImagesDisplay";
import UnderConstruction from '../UnderConstruction';
// functions
import { openCollection, handleHashChange } from "./CollectionNavBar";

// styles
import "../../styles/DoorStylePage/DoorStyle.css";

// constants
import { capitalDescription, autaeuroDescription } from "./CollectionDescription";
import { shakerDoorData, oxfordDoorData, torranceDoorData, casselberryDoorData,
            torinoDoorData, veronaDoorData, palermoDoorData,
            torinoDescription, veronaDescription, palermoDescription 
        } from "../../constants/doorImagesData";

function DoorStyle() {
    
    useEffect(() => {
        handleHashChange(openCollection);
    }, []);


    return (
        <div>
            {/* Mini Hero */}
            <MiniHero pageName={"Door Styles"} />

            {/* Navigation Horizontal Bar */}
            <div className="collectionTab">
                <button className="tablinks" data-tab='Framed' onClick={() => openCollection('Framed')} id="defaultOpen">Framed</button>
                <button className="tablinks" data-tab='Frameless' onClick={() => openCollection('Frameless')}>Frameless</button>
                <button className="tablinks" data-tab='ADA' onClick={() => openCollection('ADA')}>ADA</button>
            </div>

            {/* Capital Collection Description */}
            <div id="Framed" className="tabContent">
                <div className="capitalContainer">
                    <div className="collectionDescriptionContainer">
                        <CollectionDescription
                            collectionTitle={"Capital Collection"}
                            CollectionDescriptionList={capitalDescription}
                        />
                    </div>
                </div>
                
                {/* Capital Door Container */}
                <div className="doorStylesContainerCapital">
                    <DoorImagesDisplay header="Shaker" imageList={shakerDoorData} />
                    <br></br>
                    {/*<div className="doorFrontImagesContainer2">*/}
                        <DoorImagesDisplay header="Oxford" imageList={oxfordDoorData} />
                        <div className="verticalLine"></div>
                        <DoorImagesDisplay header="Torrance" imageList={torranceDoorData} />
                        <div className="verticalLine"></div>
                        <DoorImagesDisplay header="Casselberry" imageList={casselberryDoorData} />
                    {/*</div>*/}
                </div>

                {/* Closer look img */}
                <div className="closerLookImgContainer">
                    <br></br>
                    <img className="closerLookImg" src="/Framed 1.png" alt="Closer look framed img" />
                </div>
            </div>
    
            {/* Autaeuro Collection Tab */}
            <div id="Frameless" className="tabContent">
                <div className="autaeuroContainer">
                    <div className="collectionDescriptionContainer">
                        <CollectionDescription
                            collectionTitle={"Autaeuro Collection"}
                            CollectionDescriptionList={autaeuroDescription}
                        />
                    </div>
                </div>

                {/* Autaeuro Door Container */}
                <div className= "doorStylesContainerAutaeuro">
                    <DoorImagesDisplay header="Torino" miniDescription={torinoDescription} imageList={torinoDoorData} />
                    <br></br>
                    <DoorImagesDisplay header="Verona" miniDescription={veronaDescription} imageList={veronaDoorData} />
                    <div className="verticalLine"></div>
                    <DoorImagesDisplay header="Palermo" miniDescription={palermoDescription} imageList={palermoDoorData} />
                    
                </div>

                {/* Closer look img */}
                <div className="closerLookImgContainer">
                    <img className="closerLookImg" src="/Frameless 1.jpg" alt="Closer look frameless img" />
                </div>
            </div>
          
            {/* ADA Collection Description */}
            <div id="ADA" className="tabContent">
                <UnderConstruction />
            </div>
        </div>
    );
}

export default DoorStyle;
