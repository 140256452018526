// Mini Door Type Descriptions
export const torinoDescription = "Engineered Wood Slab Front with Matching Back";
export const veronaDescription = "Piece Painted HDF Door";
export const palermoDescription = "High Gloss PET Laminate";

// Door Images Collections
export const shakerDoorData = [
    { src: "doors/ShakerWhite.png", alt: "SW", label: "White", link:"./shop", collection: "Capital", doorStyle : "Shaker" },
    { src: "doors/ShakerAntiqueWhite.png", alt: "SAW", label: "Antique White" ,link:"./shop", collection: "Capital", doorStyle : "Shaker"},
    { src: "doors/ShakerDove.png", alt: "SD", label: "Dove" ,link:"./shop", collection: "Capital", doorStyle : "Shaker"},
    { src: "doors/ShakerGrey.png", alt: "SG", label: "Grey" ,link:"./shop", collection: "Capital", doorStyle : "Shaker"},
    { src: "doors/ShakerCinder.png", alt: "SB", label: "Cinder" ,link:"./shop", collection: "Capital", doorStyle : "Shaker"}
];

export const oxfordDoorData = [
    { src: "doors/OxfordMist.png", alt: "OM", label: "Mist" ,link:"./shop", collection: "Capital", doorStyle : "Oxford"},
    { src: "doors/OxfordToffee.png", alt: "OF", label: "Toffee" ,link:"./shop", collection: "Capital", doorStyle : "Oxford"}
];

export const torranceDoorData = [
    { src: "doors/TorranceWhite.png", alt: "TW", label: "White" ,link:"./shop", collection: "Capital", doorStyle : "Torrance"},
];

export const casselberryDoorData = [
    { src: "doors/CasselberryWhite.png", alt: "CW", label: "Antique White" ,link:"./shop", collection: "Capital", doorStyle : "Casselberry"},
    { src: "doors/CasselberrySaddle.png", alt: "CS", label: "Saddle" ,link:"./shop", collection: "Capital", doorStyle : "Casselberry"},
];

export const torinoDoorData = [
    { src: "doors/TorinoWhitePine.png", alt: "TWP", label: "White Pine" ,link:"./shop", collection: "Autaeuro", doorStyle : "Torino"},
    { src: "doors/TorinoGreyWood.png", alt: "TGW", label: "Grey Wood" ,link:"./shop", collection: "Autaeuro", doorStyle : "Torino"},
    { src: "doors/TorinoDarkWood.png", alt: "TDW", label: "Dark Wood" ,link:"./shop", collection: "Autaeuro", doorStyle : "Torino"},
];

export const veronaDoorData = [
    { src: "doors/VeronaPureBlanc.png", alt: "VPB", label: "Pure Blanc" ,link:"./shop", collection: "Autaeuro", doorStyle : "Verona"},
    { src: "doors/VeronaMidnightNavy.png", alt: "VMN", label: "Midnight Navy" ,link:"./shop", collection: "Autaeuro", doorStyle : "Verona"}
];

export const palermoDoorData = [
    { src: "doors/PalermoGlossWhite.png", alt: "PGW", label: "Gloss White" ,link:"./shop", collection: "Autaeuro", doorStyle : "Palermo"},
];
