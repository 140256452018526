import React from "react";


function SortBy({handleSortChange}) {
    return (
        <div className="sort-by">
          <label for="sort">Sort by</label>
          <select
            id="sort"
            name="sort"
            className="sort-options"
            onChange={handleSortChange}
          >
            <option value="default">Default</option>
            <option value="A-Z">A-Z</option>
            <option value="Z-A">Z-A</option>
            <option value="low-to-high">Low to High</option>
            <option value="high-to-low">High to Low</option>
          </select>
        </div>
    );
} 

export default SortBy;