import React, {useState, useEffect} from "react";
import DropdownItem from "./DropdownItem";

function DropdownContainer({filterRef, type, isFilterOpen, setFilterType, setIsFilterOpen }) {

    useEffect(() => {
        function handleClickOutside(event) {
          if (filterRef.current && !filterRef.current.contains(event.target)) {
            setIsFilterOpen(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [filterRef]);

    function handleFilterChange(event) {
        setFilterType(event.target.value);
        setIsFilterOpen(false);
    }  

    
    return (
        <div>
        {isFilterOpen && (
            <div className="filter-dropdown" ref={filterRef}>
              <div className="dropdown-header">Type</div>
              <DropdownItem containerType={"base"} filterType={type} handler={handleFilterChange}/>
              <DropdownItem containerType={"wall"} filterType={type} handler={handleFilterChange}/>
              <DropdownItem containerType={"tall"} filterType={type} handler={handleFilterChange}/>
              <DropdownItem containerType={"moulding"} filterType={type} handler={handleFilterChange}/>
              <DropdownItem containerType={"accessories"} filterType={type} handler={handleFilterChange}/>
              <DropdownItem containerType={"vanities"} filterType={type} handler={handleFilterChange}/>
              <DropdownItem containerType={"panels"} filterType={type} handler={handleFilterChange}/>
            </div>
        )}
        </div>
    );
}

export default DropdownContainer;