import React from "react";

const UnderConstruction = () => {
    return (
        <div>
            <div className="underConstructionImgContainer">
                <img className="underConstructionImg" src="Page Under Construction.png" alt="Under Construction" />
            </div>
            <div className="adaPlaceholder">
                <p>This page is currently under construction. Please check back later for more details on our ADA collection.</p>
                <p>For more information, feel free to contact us at planaheadconstructions@gmail.com.</p>
                <p>Thank you for your patience!</p>
            </div>
        </div>
    )
}

export default UnderConstruction;