import React from "react";
import "../../styles/DoorStylePage/DoorStyle.css";

// Collection Descriptions 
export const capitalDescription = {
    1: "Framed Cabinet with Full Overlay Doors and Drawers",
    2: "Under Mount Full Extension Soft Close Drawer Glides",
    3: "Concealed European Style Hinges with Soft Close Feature",
    4: "Five Piece HDF Door",
    5: "½\" Plywood Box with UV Coated Matching Exterior",
    6: "Glue & Staple or Metal Clip Assembly",
    7: "UV Coated Natural Interior"
};

export const autaeuroDescription = {
    1: "Frameless Full Access Construction",
    2: "Universal 18mm Plywood Composite Cabinet Box",
    3: "With a Grey Linen Thermofused Melamine Inside & Out",
    4: "Altamax Full Extension Soft-Close Orion Grey Drawer Box",
    5: "Optishelf Full Depth 18mm Adjustable Shelf with Full Edge Banding",
    6: "Hettich Sensys Adjustable 110° Soft-Close Hinges",
    7: "Easylevel Leg System"
};

function CollectionDescription ({collectionTitle, CollectionDescriptionList}) {
    return (
        <div>
                {/* Collection Title */}
                <div className="collectionName">{collectionTitle} </div>

                {/* Description List */}
                <ul className="a">
                {
                    Object.keys(CollectionDescriptionList).map((key) => {
                        return (
                            <li className="collectionDescriptionLi" key={key}>
                                {CollectionDescriptionList[key]}
                            </li>
                        );
                    })
                }
                </ul>
            </div>
    );
}

export default CollectionDescription;