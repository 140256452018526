import React from "react";
import { Link } from "react-router-dom";
import "../styles/DoorStylePage/MiniHero.css";

function MiniHero ({ pageName }) {

    return (    
        <div className="shop-hero">
            <img src="/shop-hero.jpg" alt="Shop Hero" />

            <div className="shop-hero-title">
                {pageName}
            </div>
            
            <div className="shop-hero-subtitle">
                <Link to="/" className="show-more-link">
                <span className="shop-hero-home">Home &gt;</span>{" "}
                </Link>
                {   
                    pageName === "Shop" ? (
                        <Link to="/doorstyle" className="show-more-link">
                            <span className="shop-hero-home"> Door Style &gt; </span>{" "}
                        </Link>
                    ) : (<span/>)
                }
                <span className="shop-hero-home"> 
                    <Link to={pageName == "Door Style"? "/doorstyle" : "/" + pageName.toLowerCase()}>
                        <span className="shop-hero-home">
                            {pageName}
                        </span>
                    </Link>
                </span>
                
            </div>
        
        </div>
    );
}

export default MiniHero;